// Home.js
import React from 'react';
import {Carousel, Container} from 'react-bootstrap'
import TOGKABanner1 from '../images/front-slider/TOGKA-banner-1.jpg'
import TOGKABanner2 from '../images/front-slider/TOGKA-banner-2.jpg'
import TOGKABanner3 from '../images/front-slider/TOGKA-banner-3.jpg'
import Helmet from 'react-helmet';
import banner from "../topBanner.jpg";

const Home = () => {
  return (
    <div>
      <img src={banner} className="App-banner" alt="logo"/>
      <Container>
        <Helmet title="Traditional Okinawan Goju Ryu Karate-do Association"/>
        <h1>The Traditional Okinawan Goju Ryu Karate-do Association</h1>
        <p>or TOGKA is an international association whose character is to promote and preserve Traditional
          Okinawan Goju Ryu Karate-do, while encouraging friendship and co-operation.
          Our style is a complete art that is well suited to Self Defence, Fitness, Discipline and Character
          Development.
          Founded in 1989 by Sensei Graham Ravey the Associations main aim is to promote Traditional Okinawan
          Goju Ryu Karate-do without politics.
          We are always happy to welcome new clubs into the association so if you are a Traditional Goju Ryu
          club and wish to join please <a href="/Contact">contact us</a>.</p>

        <Container>
          <Carousel>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={TOGKABanner1}
                alt="First slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={TOGKABanner2}
                alt="Second slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={TOGKABanner3}
                alt="Third slide"
              />
            </Carousel.Item>
          </Carousel>
        </Container>
      </Container>
    </div>
  );
};

export default Home;
